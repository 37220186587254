import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["start", "end"];

  connect() {
    this.initializeEndDateWithStartDate();
  }

  initializeEndDateWithStartDate() {
    const startDate = this.startTarget.value;
    const endDate = this.endTarget.value;

    if (!endDate && startDate) {
      this.endTarget.value = this.startTarget.value;
    }

    if (new Date(startDate) > new Date(endDate)) {
      this.endTarget.value = this.startTarget.value;
    }
  }
}
